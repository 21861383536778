export const resources = {
  en: {
    groupRegisterScreen: {
      "Create Group": "Create Group",
      Address: "Address",
      EmailID: "Email ID (optional)",
      UserType: "User Type",
      MobileNumber: "Mobile Number",
      UserName: "User Name",
      "Profile Image": "Profile Image",
      Reset: "Reset",
      Add: "Add",
      "Select role": "Select role",
    },
    registerScreen: {
      "Create User": "Create User",
      "Select role": "Select role",
      Address: "Address",
      EmailID: "Email ID (optional)",
      UserType: "User Type",
      MobileNumber: "Mobile Number",
      UserName: "User Name",
    },
    savingsGroupRegistrationScreen: {
      "Bachat Gat Registration": "Bachat Gat Registration",
      "Select district": "Select district",
      "Select taluka": "Select taluka",
      "Select employee": "Select employee",
      "Select bachat gat": "Select bachat gat",
      "Select year": "Select year",
      SavingGroup: "Saving Group",
      GroupName: "Group Name",
      BankName: "Bank Name",
      Branch: "Branch",
      IFSCCode: "IFSC Code",
      AccountNumber: "Account Number",
      MonthlySavingAmount: "Monthly Saving Amount",
      OpeningBalance: "Opening Balance",
      StartDate: "Start Date",
      DistrictName: "District Name",
      TalukaName: "Taluka Name",
      PanchayatSamitiNumber: "Panchayat Samiti Number(optional)",
      PanCardNumber: "PanCardNumber",
      VillageName: "Village Name",
      WhatsappNumber: "Whatsapp Number",
      Add: "Add",
      Cancel: "Cancel",
    },

    memberRegistrationScreen: {
      "User name is required": "User name is required",
      "Mobile number is required": "Mobile number is required",
      "Member Registration": "Member Registration",
      "Select district": "Select district",
      "Select taluka": "Select taluka",
      "Select employee": "Select employee",
      "Select bachat gat": "Select bachat gat",
      "Select designation": "Select designation",
      "Profile Image": "Profile Image",
      MobileNumber: "Mobile Number",
      GroupName: "Group Name",
      DistrictName: "District Name",
      MemberName: "Member Name",
      TalukaName: "Taluka Name",
      DesignationName: "Designation Name",
      AdharCardNumber: "Adhar Card Number",
      PanCardNumber: "PanCardNumber",
      Occupation: "Occupation",
      Age: "Age",
      DOB: "DOB",
      VillageName: "Village Name",
      WhatsappNumber: "Whatsapp Number",
      MembershipTakenDate: "Membership Taken Date",
      "Select role": "Select role",
      Address: "Address",
      EmailID: "Email ID (optional)",
      UserType: "User Type",
      UserName: "User Name",
      Add: "Add",
      Cancel: "Cancel",
    },
    employeeRegistrationScreen: {
      "Employee Registration": "Employee Registration",
      "Select district": "Select district",
      "Select taluka": "Select taluka",
      "Select employee": "Select employee",
      "Select bachat gat": "Select bachat gat",
      "Select designation": "Select designation",
      "Profile Image": "Profile Image",
      UserName: "User Name",
      EmployeeName: "Employee Name",
      GroupName: "Group Name",
      DistrictName: "District Name",
      TalukaName: "Taluka Name",
      DesignationName: "Designation Name",
      AdharCardNumber: "Adhar Card Number",
      PanCardNumber: "PanCardNumber",
      Age: "Age",
      DOB: "DOB",
      JoiningDate: "Joining Date",
      VillageName: "Village Name",
      WhatsappNumber: "Whatsapp Number",
      "Select role": "Select role",
      Address: "Address",
      EmailID: "Email ID (optional)",
      UserType: "User Type",
      Add: "Add",
      Cancel: "Cancel",
    },
    addLoanScreen: {
      "Add Loan": "Add Loan",
      "Loan Duration": "Loan Duration",
      "Select member": "Select member",
      "Select Group": "Select Group",
      "Select loan type": "Select loan type",
      MemberName: "Member Name",
      GroupName: "GroupName",
      LoanAmount: "Loan Amount",
      MonthlyInstallment: "Monthly Installment(₹)",
      LoanStartDate: "Loan Start Date",
      LoanType: "Loan Type",
      InterestRate: "InterestRate(%)",
      months: "months",
      Add: "Add",
      Cancel: "Cancel",
    },
    loanReportScreen: {
      "Select loan type": "Select loan type",
      "Loan Type": "Loan Type",
      RemainingLoanAmount: "Remaining LoanAmount",
      "Loan Report": "Loan Report",
      "Select member": "Select member",
      "Select group": "Select group",
      FromDate: "FromDate",
      ToDate: "To Date",
      MemberName: "Member Name",
      GroupName: "Group Name",
      EntryDate: "Entry Date",
      RegistrationID: "Registration ID",
      LoanAmount: "Loan Amount(₹)",
      LoanStartDate: "Loan Start Date",
      LoanEndDate: "Loan End Date",
      InterestRate: "Interest Rate(%)",
      LoanDuration: "Loan Duration(month)",
      MonthlyInstallment: "Monthly Installment(₹)",
      Delete: "Delete",
      Submit: "Submit",
    },
    userReportScreen: {
      "Select group": "Select group",
      "All Users": "All Users",
      "Selection Criteria": "Selection Criteria",
      MobileNumber: "Mobile Number",
      EmailID: "Email ID (optional)",
      UserType: "User Type",
      GroupName: "Group Name",
      UserName: "User Name",
      Address: "Address",
      Date: "Date",
      All: "All",
      GROUPADMIN: "GROUP ADMIN",
      MEMBER: "MEMBER",
      EMPLOYEE: "EMPLOYEE",
    },
    otherInvestmentScreen: {
      "Are you sure to delete investment?":
        "Are you sure to delete investment?",
      "Other Investment": "Other Investment",
      "Select group": "Select group",
      "Select investment type": "Select investment type",
      GroupName: "Group Name",
      InvestmentType: "Investment Type",
      InvestmentDate: "Investment Date",
      InvestmentIn: "Investment In",
      Amount: "Amount",
      Delete: "Delete",
      Description: "Description",
      TotalInvestment: "Total Investment",
      Update: "Update",
      Add: "Add",
      Cancel: "Cancel",
    },
    otherInvestmentReportScreen: {
      "Investment Report": "Investment Report",
      FromDate: "FromDate",
      ToDate: "To Date",
      GroupName: "Group Name",
      Submit: "Submit",
    },
    payInstallmentScreen: {
      "Penalty is required": "Penalty must be at least zero",
      "Total Saving": "Total Saving",
      "Pay Installment": "Pay Installment",
      "Select member": "Select member",
      "Select Group": "Select Group",
      RegistrationID: "Registration ID",
      "Select installment type": "Select installment type",
      Month: "Month",
      RemainingLoanAmount: "Remaining Loan Amount(₹)",
      MonthlyLoanInstallment: "Monthly Loan Installment(₹)",
      PayInstallment: "Pay Installment",
      LoanDuration: "Loan Duration(month)",
      LoanInterest: "Loan Interest",
      MonthlyBachat: "  Monthly Bachat(₹)",
      MemberName: "Member Name",
      InstallmentType: "Installment Type",
      Date: "Date",
      GroupName: "Group Name",
      LoanType: "Loan Type",
      LoanAmount: "Loan Amount(₹)",
      InterestAmount: "InterestAmount(₹)",
      InstallmentAmount: "InstallmentAmount(₹)",
      Penalty: "Penalty(₹)",
      Muddal: "Muddal(₹)",
      Add: "Add",
      Cancel: "Cancel",
      "Select loan type": "Select loan type",
    },
    regularBachatReportScreen: {
      "Total Saving": "Total Saving(₹)",
      "Regular Bachat  Report": "Regular Bachat  Report",
      "Select member": "Select member",
      "Select group": "Select group",
      FromDate: "FromDate",
      ToDate: "To Date",
      GroupName: "Group Name",
      MemberName: "Member Name",
      Date: "Date",
      RegistrationID: "Registration ID",
      RegularInstallmentAmount: "Regular Installment Amount(₹)",
      Delete: "Delete",
      Submit: "Submit",
    },
    installmentReportScreen: {
      "Installment Type": "Installment Type",
      "Installment Report": "Installment Report",
      "Select member": "Select member",
      "Select group": "Select group",
      FromDate: "FromDate",
      ToDate: "To Date",
      GroupName: "Group Name",
      MemberName: "Member Name",
      Date: "Date",
      RegistrationID: "Registration ID",
      LoanType: "Loan Type",
      InstallmentAmount: "Installment Amount(₹)",
      RegularInstallmentAmount: "Regular Installment Amount(₹)",
      InterestAmount: "Interest Amount(₹)",
      Muddal: "Muddal(₹)",
      RemainingLoanAmount: "Remaining Loan Amount(₹)",
      Delete: "Delete",
      Submit: "Submit",
    },
    expenseTypeAddScreen: {
      "Expense Type": "Expense Type",
      ExpenseType: "Expense Type",
      Edit: "Edit",
      Delete: "Delete",
      Update: "Update",
      Add: "Add",
      Cancel: "Cancel",
    },
    expenseSubTypeScreen: {
      "Expense Type": "Expense Type",
      "Select Type": "Select Type",
      "Expense Sub Type": "Expense Sub Type",
      ExpenseSubType: "Expense Sub Type",
      ExpenseType: "Expense Type",
      Edit: "Edit",
      Delete: "Delete",
      Update: "Update",
      Add: "Add",
      Cancel: "Cancel",
    },
    addProductScreen: {
      "Add Product": "Add Product",
      "Select product": "Select product",
      "Select group": "Select group",
      GroupName: "Group Name",
      ProductName: "Product Name",
      ProductType: "Product Type",
      Description: "Description",
      Edit: "Edit",
      Delete: "Delete",
      Update: "Update",
      Add: "Add",
      Cancel: "Cancel",
      Image: "Image",
      "Product Image": "Product Image",
    },
    addExpenseClaimScreen: {
      "Click here to upload image": "Click here to upload image",
      "Add Expense Claim": "Add Expense Claim",
      "Expense Type": "Expense Type",
      "Select user": "Select user",
      "Expense Sub Type": "Expense Sub Type",
      "Select Image": "Select Image",
      Date: "Date",
      UserName: "User Name",
      GroupName: "Group Name",
      Amount: "Amount",
      GroupAdminRemark: "Group Admin Remark",
      Description: "Description",
      Document: "Document",
      ExpenseSubType: "Expense Sub Type",
      ExpenseType: "Expense Type",
      ClaimDate: "Claim Date",
      Edit: "Edit",
      Delete: "Delete",
      Update: "Update",
      Add: "Add",
      Cancel: "Cancel",
    },
    expenseClaimReportScreen: {
      "Expense Claim Report": "Expense Claim Report",
      "Select user": "Select user",
      "Select group": "Select group",
      FromDate: "From Date",
      ToDate: "To Date",
      GroupName: "Group Name",
      UserName: "User Name",
      Document: "Document",
      GroupAdminRemark: "Group Admin Remark",
      Description: "Description",
      ExpenseSubType: "Expense Sub Type",
      ExpenseType: "Expense Type",
      ClaimDate: "Claim Date",
      Delete: "Delete",
      Amount: "Amount",
      Submit: "Submit",
    },
    smsReportScreen: {
      UserType: "User Type",
      "Select role": "Select role",
      "Sent SMS Report": "Sent SMS Report",
      "Select user": "Select user",
      FromDate: "From Date",
      ToDate: "To Date",
      UserName: "User Name",
      Date: "Date",
      MobileNumber: "Mobile Number",
      Message: "Message",
      Type: "Type",
      MessageMedium: "Message Medium",
      Submit: "Submit",
    },
    mainMenuList: {
      Notice: "Notice",
      News: "News",
      "Company Subscription": "Company Subscription",
      "All User Report": "All User Report",
      "Details Register": "Details Register",
      "Group Register": "Group Register",
      "Subscription Charge": "Subscription Charge",
      Home: "Home",
      Product: "Product",
      Register: "Register",
      Loan: "Loan",
      User: "User",
      Investment: "Investment",
      Installment: "Installment",
      Master: "Master",
      Expense: "Expense",
      Report: "Report",
      Setting: "Setting",
      "Group Income": "Group Income",
      "Balance Sheet": "Balance Sheet",
      "Total Balance Sheet": "Total Balance Sheet",
      "Monthly Balance Sheet": "Monthly Balance Sheet",
    },
    subMenuList: {
      "Add Notice": "Add Notice",
      "Notice Report": "Notice Report",
      "Add Slider": "Add Slider",
      "Add News": "Add News",
      Report: "Report",
      "Bachat Gat": "Bachat Gat",
      "Income Report": "Income Report",
      "Add Income": "Add Income",
      "Product Type": "Product Type",
      "Saving Groups": "Saving Groups",
      "Investment Type": "Investment Type",
      "Add Loan": "Add Loan",
      "Loan Report": "Loan Report",
      "All User": "All User",
      "Other Investment": "Other Investment",
      "Investment Report": "Investment Report",
      "Pay Installment": "Pay Installment",
      "Bachat Report": "Bachat Report",
      "Loan Installment Report": "Loan Installment Report",
      "Expense Sub Type": "Expense Sub Type",
      "Expense Type": "Expense Type",
      "Add Product": "Add Product",
      "Add Expense": "Add Expense",
      "Expense Claim Report": "Expense Report",
      "User Report": "User Report",
      "Sent SMS Report": "Sent SMS Report",
      "Key Setting": "Key Setting",
      "Message Setting": "Message Setting",
      "SMS Setting": "SMS Setting",
      Member: "Member",
      Employee: "Employee",
      "Total Balance Sheet": "Total Balance Sheet",
      "Monthly Balance Sheet": "Monthly Balance Sheet",
    },
    loginScreen: {
      "Welcome to Shree Systems": "Welcome to Shree Systems",
      "Please sign-in to your account": "Please sign-in to your account",
      MobileNumber: "Mobile Number",
      Password: "Password",
      "Forgot password": "Forgot password?",
      "Sign In": "Sign In",
    },
    forgotPassword: {
      "Forgot Password": "Forgot Password?",
      "Do you want to login": "Do you want to login?",
      "Return to Login": "Return to Login",
      "You'll receive information on how to get some rest":
        "You'll receive information on how to get some rest",
      MobileNumber: "Mobile Number",
    },
    accountMenu: {
      Profile: "Profile",
      "Change Password": "Change Password",
      Logout: "Logout",
      GroupName: "Group Name",
      Date: "Date",
    },
    public: {
      Home: "Home",
      About: "About",
      "Sign in": "Sign in",
    },
    changePassword: {
      "Change Password": "Change Password",
      "Old Password": "Old Password",
      "New Password": "New Password",
      "Confirm Password": "Confirm Password",
      "Please provide valid new password": "Please provide valid new password",
      "Please provide valid old password": "Please provide valid old password",
      "Please provide valid confirm Password":
        "Please provide valid confirm Password",
    },
    addProductTypeScreen: {
      "Product Type": "Product Type",
      "Product type is required": "Product type is required",
      "Are you sure to delete product type":
        "Are you sure to delete product type?",
      "Delete Confirmation": "Delete Confirmation",
      Description: "Description",
      Edit: "Edit",
      Delete: "Delete",
      Update: "Update",
      Add: "Add",
      Cancel: "Cancel",
    },
    addInvestmentTypeScreen: {
      "Investment Type": "Investment Type",
      Description: "Description",
      Edit: "Edit",
      Delete: "Delete",
      "Delete Confirmation": "Delete Confirmation",
      "Are you sure to delete investment type":
        "Are you sure to delete investment type?",
    },
    profile: {
      Profile: "Profile",
      EmailID: "Email ID",
      "User Name": "User Name",
      "Mobile Number": "Mobile Number",
      "Profile Image": "Profile Image",
      "Click here to upload image": "Click here to upload image",
      Update: "Update",
      Add: "Add",
      Cancel: "Cancel",
    },
    editScreen: {
      "User Edit": "User Edit",
      "User Name": "User Name",
      MobileNumber: "Mobile Number",
      EmailID: "Email ID",
      GroupName: "Group Name",
      DistrictName: "District Name",
      TalukaName: "Taluka Name",
      UserType: "User Type",
      PanCardNumber: "PanCardNumber",
      AdharCardNumber: "Adhar Card Number",
    },
    addSubscriptionChargeScreen: {
      "Add Subscription Charge": "Add Subscription Charge",
      "User Name": "User Name",
      "Plan Type": "Plan Type",
      "End Date": "End Date",
      "Select plan": "Select plan",
      "Select user": "Select user",
      PlanType: "Plan Type",
      Charge: "Charge",
      Edit: "Edit",
      UserName: "User Name",
      Delete: "Delete",
      "Are you sure to delete subscription charge":
        "Are you sure to delete subscription charge",
      "Charge amount is required": "Charge amount is required",
      Update: "Update",
      Add: "Add",
      Cancel: "Cancel",
    },
    companyDashboardScreen: {
      Dashboard: "Dashboard",
      "Group Admin": "Group Admin",
      "Total User": "Total User",
      "Total Subscribers": "Total Subscribers",
    },
    groupAdminScreen: {
      Dashboard: "Dashboard",
      "Total Investment": "Total Investment",
      "Total User": "Total User",
      "Total Loan Disbursement": "Total Loan Disbursement",
      "Total Expense Claim": "Total Expense Claim",
      "Recent Products": "Recent Products",
      GroupName: "Group Name",
      Date: "Date",
      "Group Income": "Group Income",
      "Remaining Amount": "Remaining Amount",
    },
    addGroupIncome: {
      "Add Income": "Add Income",
      Date: "Date",
      GroupName: "Group Name",
      Amount: "Amount",
      Description: "Description",
      "Select bachat gat": "Select bachat gat",
      "Total Income": "Total Income",
      Update: "Update",
      Add: "Add",
      Cancel: "Cancel",
    },
    groupIncomeReportScreen: {
      "Select group": "Select group",
      FromDate: "FromDate",
      ToDate: "To Date",
      GroupName: "Group Name",
      Date: "Date",
      Delete: "Delete",
      TotalIncome: "Total Income",
      "Group Income Report": "Group Income Report",
      Submit: "Submit",
    },
    bachatGatReportScreen: {
      DistrictName: "District Name",
      TalukaName: "Taluka Name",
      "Select district": "Select district",
      "Select taluka": "Select taluka",
      GroupName: "Group Name",
      "Group Admin": "Group Admin",
      "BachatGat Report": "BachatGat Report",
      Submit: "Submit",
    },
    addNewsScreen: {
      "Add News": "Add News",
      News: "News",
      Description: "Description",
      Cancel: "Cancel",
      Add: "Add",
      FromDate: "FromDate",
      ToDate: "To Date",
      "Delete All": "Delete All",
      "News Report": "News Report",
      Submit: "Submit",
      Message: "Message",
      Delete: "Delete",
    },
    addSliderScreen: {
      "Add Slider": "Add Slider",
      "Select Type": "Select Type",
      Description: "Description",
      "Select Sequence": "Select Sequence",
      "Click here to upload Image": "Click here to upload Image",
      Type: "Type",
      Image: "Image",
      "Display Sequence": "Display Sequence",
      Edit: "Edit",
      Delete: "Delete",
      "Description is required": "Description is required",
      Update: "Update",
      Add: "Add",
      Cancel: "Cancel",
    },
    noticeDetailsScreen: {
      "Notice Report": "Notice Report",
      "Add Notice": "Add Notice",
      Notice: "Notice",
      Description: "Description",
      Cancel: "Cancel",
      Add: "Add",
      FromDate: "FromDate",
      ToDate: "To Date",
      "Delete All": "Delete All",
      Submit: "Submit",
    },
    balanceSheet: {
      FromDate: "FromDate",
      ToDate: "ToDate",
      GroupName: "Group Name",
      Submit: "Submit",
      "Total Balance Sheet": "Total Balance Sheet",
      "Monthly Balance Sheet": "Monthly Balance Sheet",
    },
  },
  mr: {
    noticeDetailsScreen: {
      "Notice Report": "सूचना अहवाल",
      "Add Notice": "सूचना जोडा",
      Notice: "सूचना",
      Description: "वर्णन",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      Cancel: "रद्द करा",
      Add: "जोडा",
      Delete: "हटवा",
      "Delete All": "सर्व हटवा",
      Submit: "जतन करा",
    },
    addSliderScreen: {
      "Description is required": "Description is required",
      "Add Slider": "स्लाइडर जोडा",
      "Select Type": "प्रकार निवडा",
      Description: "वर्णन",
      "Select Sequence": "अनुक्रम निवडा",
      "Click here to upload Image": "प्रतिमा अपलोड करण्यासाठी येथे क्लिक करा",
      Type: "प्रकार",
      Image: "प्रतिमा",
      "Display Sequence": "प्रदर्शन क्रम",
      Edit: "संपादित करा",
      Delete: "हटवा",
      Update: "अपडेट करा",
      Add: "जतन करा",
      Cancel: "रद्द करा",
    },
    addNewsScreen: {
      "Add News": "बातम्या जोडा",
      News: "बातम्या",
      Description: "वर्णन",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      Cancel: "रद्द करा",
      Add: "जोडा",
      "Delete All": "सर्व हटवा",
      "News Report": "बातम्या अहवाल",
      Submit: "जतन करा",
      Message: "संदेश",
      Delete: "हटवा",
    },
    bachatGatReportScreen: {
      "BachatGat Report": "बचतगट अहवाल",
      DistrictName: "जिल्ह्याचे नाव",
      TalukaName: "तालुक्याचे नाव",
      "Select district": "जिल्हा निवडा",
      "Select taluka": "तालुका निवडा",
      GroupName: "गटाचे नाव",
      "Group Admin": "अध्यक्ष",
      Submit: "जतन करा",
    },
    groupIncomeReportScreen: {
      "Group Income Report": "गट उत्पन्न अहवाल",
      "Add Income": "उत्पन्न जोडा",
      Date: "तारीख",
      "Select group": "गट निवडा",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      GroupName: "गटाचे नाव",
      Amount: "रक्कम",
      Description: "वर्णन",
      "Total Income": "एकूण उत्पन्न",
      TotalIncome: "एकूण उत्पन्न",
      Delete: "हटवा",
      Submit: "जतन करा",
    },
    addGroupIncome: {
      "Add Income": "उत्पन्न जोडा",
      Date: "तारीख",
      GroupName: "गटाचे नाव",
      Amount: "रक्कम",
      Description: "वर्णन",
      "Select bachat gat": "बचत गट निवडा",
      "Total Income": "एकूण उत्पन्न",
      Update: "अपडेट करा",
      Add: "जतन करा ",
      Cancel: "रद्द करा",
    },
    groupAdminScreen: {
      Dashboard: "डॅशबोर्ड",
      "Group Admin": "अध्यक्ष",
      "Total User": "एकूण वापरकर्ता",
      "Total Investment": "एकूण गुंतवणूक",
      "Total Loan Disbursement": "एकूण कर्ज वितरण",
      "Total Expense Claim": "एकूण खर्चाचा व्हाउचर",
      "Recent Products": "उत्पादने",
      "View All": "View All",
      GroupName: "गटाचे नाव",
      Date: "तारीख",
      "Remaining Amount": "उर्वरित रक्कम",
      "Group Income": "गट उत्पन्न",
    },
    groupMemberScreen: {
      Dashboard: "डॅशबोर्ड",
      "Group Admin": "अध्यक्ष",
      "Total User": "एकूण वापरकर्ता",
      "Total Investment": "एकूण गुंतवणूक",
      "Total Loan Disbursement": "एकूण कर्ज वितरण",
      "Total Expense Claim": "एकूण खर्चाचा व्हाउचर",
      "Recent Products": "उत्पादने",
      "View All": "View All",
      GroupName: "गटाचे नाव",
      Date: "तारीख",
      "Remaining Amount": "उर्वरित रक्कम",
      "Group Income": "गट उत्पन्न",
    },
    companyDashboardScreen: {
      Dashboard: "डॅशबोर्ड",
      "Group Admin": "अध्यक्ष",
      "Total User": "एकूण वापरकर्ता",
      "Total Subscribers": "एकूण सशुल्क सदस्य",
      "View All": "सर्व पहा",
    },
    addSubscriptionChargeScreen: {
      "Add Subscription Charge": "सदस्यता शुल्क जोडा",
      "User Name": "वापरकर्ता नाव",
      "Plan Type": "योजना प्रकार",
      "End Date": "शेवटची तारीख",
      UserName: "वापरकर्ता नाव",
      PlanType: "योजना प्रकार",
      Charge: "चार्ज ",
      Edit: "संपादित करा",
      Delete: "हटवा",
      "Select plan": "योजना निवडा",
      "Select user": "वापरकर्ता निवडा",
      "Are you sure to delete subscription charge":
        "तुमची खात्री आहे की सदस्यता शुल्क हटवायचे आहे",
      "Charge amount is required": "शुल्काची रक्कम आवश्यक आहे",
      Update: "अपडेट करा",
      Add: "जतन करा ",
      Cancel: "रद्द करा",
    },
    editScreen: {
      "User Edit": "वापरकर्ता संपादन",
      "User Name": "वापरकर्ता नाव",
      MobileNumber: "मोबाईल नंबर",
      EmailID: "ईमेल आयडी",
      DistrictName: "जिल्ह्याचे नाव",
      GroupName: "गटाचे नाव",
      TalukaName: "तालुक्याचे नाव",
      UserType: "वापरकर्ता प्रकार",
      PanCardNumber: "पॅनकार्ड क्रमांक",
      AdharCardNumber: "आधार कार्ड क्रमांक",
    },
    profile: {
      Address: "पत्ता",
      Profile: "प्रोफाइल",
      EmailID: "ईमेल आयडी",
      "User Name": "वापरकर्ता नाव",
      "Mobile Number": "मोबाईल नंबर",
      "Profile Image": "प्रोफाइल प्रतिमा",
      "Click here to upload image": "इमेज अपलोड करण्यासाठी येथे क्लिक करा",
    },
    addInvestmentTypeScreen: {
      "Investment Type": "जमा व्हाउचर",
      "Delete Confirmation": "पुष्टीकरण हटवा",
      "Are you sure to delete investment type":
        "तुम्हाला जमा व्हाउचर हटवण्याची खात्री आहे का?",
      Description: "वर्णन",
      Edit: "संपादित करा",
      Delete: "हटवा",
      Update: "अपडेट करा",
      Add: "जतन करा ",
      Cancel: "रद्द करा",
    },
    addProductTypeScreen: {
      "Product Type": "उत्पादन प्रकार",
      "Delete Confirmation": "पुष्टीकरण हटवा",
      "Product type is required": "उत्पादन प्रकार आवश्यक आहे",
      "Are you sure to delete product type":
        "तुम्हाला उत्पादन प्रकार हटवण्याची खात्री आहे का?",
      Description: "वर्णन",
      Edit: "संपादित करा",
      Delete: "हटवा",
      Update: "अपडेट करा",
      Add: "जतन करा ",
      Cancel: "रद्द करा",
    },
    changePassword: {
      "Change Password": "पासवर्ड बदला",
      "Old Password": "जुना पासवर्ड",
      "New Password": "नवीन पासवर्ड",
      "Confirm Password": "पासवर्डची पुष्टी करा",
      "Please provide valid new password": "कृपया वैध नवीन पासवर्ड द्या",
      "Please provide valid old password": "कृपया वैध जुना पासवर्ड द्या",
      "Please provide valid confirm Password":
        "कृपया वैध पुष्टी संकेतशब्द प्रदान करा",
    },
    public: {
      Home: "मुखपृष्ठ",
      About: "प्रकल्पाबद्दल",
      "Sign in": "साइन इन",
    },
    accountMenu: {
      Profile: "प्रोफाइल",
      Logout: "बाहेर पडणे",
      "Change Password": "पासवर्ड बदला",
      GroupName: "गटाचे नाव",
      Date: "तारीख",
    },
    forgotPassword: {
      "Forgot Password": "पासवर्ड विसरलात?",
      "Return to Login": "लॉगिन वर परत या",
      "Do you want to login": "तुम्हाला लॉगिन करायचे आहे का?",
      "You'll receive information on how to get some rest":
        "तुम्हाला माहिती मिळेल",
      MobileNumber: "मोबाईल नंबर",
    },
    loginScreen: {
      "Welcome to Shree Systems": "श्री सिस्टिम्स मध्ये आपले स्वागत आहे!",
      "Please sign-in to your account": "साइन इन करा",
      MobileNumber: "मोबाईल नंबर",
      Password: "पासवर्ड",
      "Forgot password": "पासवर्ड विसरलात?",
      "Sign In": "साइन इन",
    },
    groupRegisterScreen: {
      "Create Group": "गट तयार करा",
      UserName: "वापरकर्ता नाव",
      MobileNumber: "मोबाईल नंबर",
      UserType: "वापरकर्ता प्रकार",
      Address: "पत्ता",
      EmailID: "ईमेल आयडी (वैकल्पिक)",
      "Profile Image": "प्रोफाइल प्रतिमा",
      Reset: "रद्द करा ",
      Add: "जतन करा ",
      "Select role": "वापरकर्ता भूमिका निवडा",
    },
    registerScreen: {
      "Create User": "वापरकर्ता तयार करा",
      "Select role": "वापरकर्ता भूमिका निवडा",
      UserName: "वापरकर्ता नाव",
      MobileNumber: "मोबाईल नंबर",
      UserType: "वापरकर्ता प्रकार",
      Address: "पत्ता",
      EmailID: "ईमेल आयडी (वैकल्पिक)",
    },
    savingsGroupRegistrationScreen: {
      "Bachat Gat Registration": "बचत गट नोंदणी",
      "Select district": "जिल्हा निवडा",
      "Select taluka": "तालुका निवडा",
      "Select employee": "कर्मचारी निवडा",
      "Select bachat gat": "बचत गट निवडा",
      "Select year": "वर्ष निवडा",
      SavingGroup: "Saving Group",
      Selectyear: "वर्ष निवडा",
      GroupName: "गटाचे नाव",
      BankName: "बँकेचे नाव",
      Branch: "शाखा",
      IFSCCode: "IFSC कोड",
      AccountNumber: "खाते क्रमांक",
      MonthlySavingAmount: "मासिक बचत रक्कम",
      OpeningBalance: "प्रारंभिक शिल्लक",
      StartDate: "प्रारंभ तारीख",
      DistrictName: "जिल्ह्याचे नाव",
      TalukaName: "तालुक्याचे नाव",
      PanchayatSamitiNumber: "पंचायत समिती क्रमांक(वैकल्पिक)",
      PanCardNumber: "पॅनकार्ड क्रमांक",
      VillageName: "गावाचे नाव",
      WhatsappNumber: "Whatsapp नंबर",
      Add: "जतन करा ",
      Cancel: "रद्द करा",
    },
    memberRegistrationScreen: {
      "User name is required": "वापरकर्ता नाव आवश्यक आहे",
      "Mobile number is required": "मोबाइल नंबर आवश्यक आहे",
      "Member Registration": "सदस्य नोंदणी",
      "Select district": "जिल्हा निवडा",
      "Select taluka": "तालुका निवडा",
      "Select employee": "कर्मचारी निवडा",
      "Select bachat gat": "बचत गट निवडा",
      "Select designation": "पदनाम निवडा",
      "Select role": "वापरकर्ता भूमिका निवडा",
      "Profile Image": "प्रोफाइल प्रतिमा",
      UserName: "वापरकर्ता नाव",
      MobileNumber: "मोबाईल नंबर",
      GroupName: "गटाचे नाव",
      MemberName: "सदस्याचे नाव",
      DistrictName: "जिल्ह्याचे नाव",
      TalukaName: "तालुक्याचे नाव",
      DesignationName: "पद नाम",
      AdharCardNumber: "आधार कार्ड क्रमांक",
      PanCardNumber: "पॅनकार्ड क्रमांक",
      Occupation: "व्यवसाय",
      Age: "वय",
      DOB: "जन्मतारीख",
      VillageName: "गावाचे नाव",
      WhatsappNumber: "Whatsapp नंबर",
      MembershipTakenDate: "सदस्यत्व घेतल्याची तारीख",
      UserType: "वापरकर्ता प्रकार",
      Address: "पत्ता",
      EmailID: "ईमेल आयडी (वैकल्पिक)",
      Add: "जतन करा ",
      Cancel: "रद्द करा",
    },
    employeeRegistrationScreen: {
      "Employee Registration": "कर्मचारी नोंदणी",
      "Select district": "जिल्हा निवडा",
      "Select taluka": "तालुका निवडा",
      "Select employee": "कर्मचारी निवडा",
      "Select bachat gat": "बचत गट निवडा",
      "Select designation": "पदनाम निवडा",
      "Select role": "वापरकर्ता भूमिका निवडा",
      "Profile Image": "प्रोफाइल प्रतिमा",
      UserName: "वापरकर्ता नाव",
      EmployeeName: "कर्मचारी नाव",
      GroupName: "गटाचे नाव",
      DistrictName: "जिल्ह्याचे नाव",
      TalukaName: "तालुक्याचे नाव",
      DesignationName: "पद नाम",
      AdharCardNumber: "आधार कार्ड क्रमांक",
      PanCardNumber: "पॅनकार्ड क्रमांक",
      Age: "वय",
      DOB: "जन्मतारीख",
      JoiningDate: "सामील होण्याची तारीख",
      VillageName: "गावाचे नाव",
      WhatsappNumber: "Whatsapp नंबर",
      UserType: "वापरकर्ता प्रकार",
      Address: "पत्ता",
      EmailID: "ईमेल आयडी (वैकल्पिक)",
      Add: "जतन करा ",
      Cancel: "रद्द करा",
    },
    addLoanScreen: {
      "Add Loan": "कर्ज जोडा",
      "Loan Duration": "कर्जाचा कालावधी",
      "Select member": "सदस्य निवडा",
      "Select Group": "गट निवडा",
      "Select loan type": "कर्जाचा प्रकार निवडा",
      MemberName: "सदस्याचे नाव",
      GroupName: "गटाचे नाव",
      LoanAmount: "कर्जाची रक्कम",
      MonthlyInstallment: "मासिक हप्ता(₹)",
      LoanStartDate: "कर्ज सुरू तारीख",
      LoanType: "कर्जाचा प्रकार",
      InterestRate: "व्याज दर(%)",
      months: "महिने",
      Add: "जतन करा ",
      Cancel: "रद्द करा",
    },
    loanReportScreen: {
      "Select loan type": "कर्जाचा प्रकार निवडा",
      "Loan Type": "कर्जाचा प्रकार",
      RemainingLoanAmount: "उर्वरित कर्जाची रक्कम",
      "Loan Report": "कर्ज अहवाल",
      "Select member": "सदस्य निवडा",
      "Select group": "गट निवडा",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      GroupName: "गटाचे नाव",
      MemberName: "सदस्याचे नाव",
      EntryDate: "कर्जाची तारीख",
      RegistrationID: "नोंदणी क्रमांक",
      LoanAmount: "कर्जाची रक्कम(₹)",
      LoanStartDate: "कर्ज सुरू होण्याची तारीख",
      LoanEndDate: "कर्ज समाप्ती तारीख",
      InterestRate: "व्याज दर(%)",
      LoanDuration: "कर्जाचा कालावधी(महिना)",
      MonthlyInstallment: "मासिक हप्ता(₹)",
      Delete: "हटवा",
      Submit: "जतन करा",
    },
    userReportScreen: { 
      "Select group": "गट निवडा",
      "All Users": "सर्व वापरकर्ते",
      All: "सर्व वापरकर्ता",
      GROUPADMIN: "अध्यक्ष",
      MEMBER: "सदस्य",
      EMPLOYEE: "कर्मचारी",
      "Selection Criteria": "निवड निकष",
      UserName: "वापरकर्ता नाव",
      MobileNumber: "मोबाईल नंबर",
      UserType: "वापरकर्ता प्रकार",
      EmailID: "ईमेल आयडी (वैकल्पिक)",
      GroupName: "गटाचे नाव",
      Address: "पत्ता",
      Date: "तारीख",
    },
    otherInvestmentScreen: {
      "Are you sure to delete investment?":
        "तुम्हाला गुंतवणुक हटवण्याची खात्री आहे का?",
      "Other Investment": "इतर गुंतवणूक",
      "Select group": "गट निवडा",
      "Select investment type": "गुंतवणूक प्रकार निवडा",
      GroupName: "गटाचे नाव",
      InvestmentType: "जमा व्हाउचर",
      InvestmentDate: "गुंतवणुकीची तारीख",
      InvestmentIn: "गुंतवणूक",
      Amount: "रक्कम",
      Delete: "हटवा",
      TotalInvestment: "एकूण गुंतवणूक",
      Description: "वर्णन",
      Update: "अपडेट करा",
      Add: "जतन करा ",
      Cancel: "रद्द करा",
    },
    otherInvestmentReportScreen: {
      "Investment Report": "गुंतवणूक अहवाल",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      GroupName: "गटाचे नाव",
      Submit: "जतन करा",
    },
    payInstallmentScreen: {
      "Penalty is required": "दंड किमान शून्य असावा.",
      "Total Saving": "एकूण बचत",
      "Pay Installment": "हप्ता भरा",
      "Select member": "सदस्य निवडा",
      "Select Group": "गट निवडा",
      "Select installment type": "हप्ता प्रकार निवडा",
      Month: "महिने",
      RemainingLoanAmount: "उर्वरित कर्जाची रक्कम (₹)",
      MonthlyLoanInstallment: "मासिक कर्जाचा हप्ता (₹)",
      PayInstallment: "हप्ता भरा",
      LoanDuration: "कर्जाचा कालावधी(महिना)",
      LoanInterest: "कर्जाचे व्याज",
      MonthlyBachat: "मासिक बचत(₹)",
      MemberName: "सदस्याचे नाव",
      InstallmentType: "हप्ता प्रकार",
      Date: "तारीख",
      GroupName: "गटाचे नाव",
      LoanType: "कर्जाचा प्रकार",
      LoanAmount: "कर्जाची रक्कम(₹)",
      InterestAmount: "व्याजाची रक्कम(₹)",
      InstallmentAmount: "हप्त्याची रक्कम(₹)",
      Penalty: "दंड(₹)",
      Muddal: "मुद्दल(₹)",
      RegistrationID: "नोंदणी क्रमांक",
      Add: "जतन करा ",
      Cancel: "रद्द करा",
      "Select loan type": "कर्जाचा प्रकार निवडा",
    },
    regularBachatReportScreen: {
      "Total Saving": "एकूण बचत(₹)",
      "Regular Bachat  Report": "नियमित बचत अहवाल",
      "Select member": "सदस्य निवडा",
      "Select group": "गट निवडा",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      GroupName: "गटाचे नाव",
      MemberName: "सदस्याचे नाव",
      Date: "तारीख",
      RegistrationID: "नोंदणी क्रमांक",
      RegularInstallmentAmount: "नियमित हप्त्याची रक्कम(₹)",
      Delete: "हटवा",
      Submit: "जतन करा",
    },
    installmentReportScreen: {
      "Installment Type": "हप्ता प्रकार",
      "Installment Report": "हप्ता अहवाल",
      "Select member": "सदस्य निवडा",
      "Select group": "गट निवडा",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      GroupName: "गटाचे नाव",
      MemberName: "सदस्याचे नाव",
      Date: "तारीख",
      RegistrationID: "नोंदणी क्रमांक",
      LoanType: "कर्जाचा प्रकार",
      InstallmentAmount: "हप्त्याची रक्कम(₹)",
      RegularInstallmentAmount: "नियमित हप्त्याची रक्कम(₹)",
      InterestAmount: "व्याजाची रक्कम(₹)",
      Muddal: "मुद्दल(₹)",
      RemainingLoanAmount: "उर्वरित कर्जाची रक्कम(₹)",
      Delete: "हटवा",
      Submit: "जतन करा",
    },
    expenseTypeAddScreen: {
      "Expense Type": "खर्चाचा प्रकार",
      ExpenseType: "खर्चाचा प्रकार",
      Edit: "संपादित करा",
      Delete: "हटवा",
      Update: "अपडेट करा",
      Add: "जतन करा ",
      Cancel: "रद्द करा",
    },
    expenseSubTypeScreen: {
      "Expense Type": "खर्चाचा प्रकार",
      "Expense Sub Type": "खर्च उपप्रकार",
      "Select Type": "प्रकार निवडा",
      ExpenseSubType: "खर्च उपप्रकार",
      ExpenseType: "खर्चाचा प्रकार",
      Edit: "संपादित करा",
      Delete: "हटवा",
      Update: "अपडेट करा",
      Add: "जतन करा ",
      Cancel: "रद्द करा",
    },
    addProductScreen: {
      "Add Product": "उत्पादन जोडा",
      "Select product": "उत्पादन निवडा",
      "Select group": "गट निवडा",
      GroupName: "गटाचे नाव",
      ProductName: "उत्पादनाचे नांव",
      ProductType: "उत्पादन प्रकार",
      Description: "वर्णन",
      Edit: "संपादित करा",
      Delete: "हटवा",
      Update: "अपडेट करा",
      Add: "जतन करा ",
      Cancel: "रद्द करा",
      Image: "प्रतिमा",
      "Product Image": "उत्पादन प्रतिमा",
    },
    addExpenseClaimScreen: {
      "Click here to upload image": "पावती अपलोड करण्यासाठी येथे क्लिक करा",
      "Add Expense Claim": "खर्चाचा व्हाउचर जोडा",
      "Select group": "गट निवडा",
      "Select user": "वापरकर्ता निवडा",
      "Expense Type": "खर्चाचा प्रकार",
      "Expense Sub Type": "खर्च उपप्रकार",
      "Select Image": "पावती निवडा",
      Date: "तारीख",
      UserName: "वापरकर्ता नाव",
      GroupName: "गटाचे नाव",
      Amount: "रक्कम",
      GroupAdminRemark: "अध्यक्ष टिप्पणी",
      Description: "वर्णन",
      Document: "दस्तऐवज",
      ExpenseSubType: "खर्च उपप्रकार",
      ExpenseType: "खर्चाचा प्रकार",
      ClaimDate: "खर्च व्हाउचर तारीख",
      Edit: "संपादित करा",
      Delete: "हटवा",
      Update: "अपडेट करा",
      Add: "जतन करा ",
      Cancel: "रद्द करा",
    },
    expenseClaimReportScreen: {
      "Expense Claim Report": "खर्चाचा अहवाल",
      "Select user": "वापरकर्ता निवडा",
      "Select group": "गट निवडा",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      GroupName: "गटाचे नाव",
      UserName: "वापरकर्ता नाव",
      Document: "दस्तऐवज",
      ExpenseSubType: "खर्च उपप्रकार",
      ExpenseType: "खर्चाचा प्रकार",
      ClaimDate: "खर्च व्हाउचर तारीख",
      Description: "वर्णन",
      Delete: "हटवा",
      GroupAdminRemark: "गट प्रशासक टिप्पणी",
      Amount: "रक्कम",
      Submit: "जतन करा",
    },
    smsReportScreen: {
      "Select role": "वापरकर्ता भूमिका निवडा",
      UserType: "वापरकर्ता प्रकार",
      "Sent SMS Report": "एसएमएस रिपोर्ट",
      "Select user": "वापरकर्ता निवडा",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      UserName: "वापरकर्ता नाव",
      Date: "तारीख",
      MobileNumber: "मोबाईल नंबर",
      Message: "संदेश",
      Type: "प्रकार",
      MessageMedium: "संदेश माध्यम",
      Submit: "जतन करा",
    },
    mainMenuList: {
      Notice: "सूचना ",
      News: "बातम्या",
      "Company Subscription": "कंपनी सदस्यता",
      "All User Report": "सर्व वापरकर्ता",
      "Details Register": "तपशील नोंदणी",
      "Group Register": "गट नोंदणी",
      "Subscription Charge": "सदस्यता शुल्क",
      Home: "मुखपृष्ठ",
      Product: "उत्पादन",
      Register: "नोंदणी करा",
      Loan: "कर्ज",
      User: "वापरकर्ता",
      Investment: "जमा व्हाउचर",
      Installment: "हप्ता नोंद",
      Master: "मास्टर",
      Expense: "खर्चाचा व्हाउचर",
      Report: "अहवाल",
      Setting: "सेटिंग",
      "Group Income": "गट उत्पन्न",
      "Balance Sheet": "ताळेबंद",
    },
    subMenuList: {
      "Add Notice": "सूचना जोडा",
      "Notice Report": "सूचना अहवाल",
      "Add Slider": "स्लाइडर जोडा",
      "Add News": "बातम्या जोडा",
      Report: "अहवाल",
      "Bachat Gat": "बचत गट",
      "Product Type": "उत्पादन प्रकार",
      "Investment Type": "व्हाउचर प्रकार",
      "Saving Groups": "बचत गट",
      "Add Loan": "कर्ज जोडा",
      "Loan Report": "कर्ज अहवाल",
      "All User": "सर्व वापरकर्ता",
      "Other Investment": "व्हाउचर जोडा",
      "Investment Report": "जमा अहवाल",
      "Pay Installment": "हप्ता भरा",
      "Bachat Report": "बचत अहवाल",
      "Loan Installment Report": "कर्ज हप्त्याचा अहवाल",
      "Expense Type": "खर्चाचा प्रकार",
      "Expense Sub Type": "खर्च उपप्रकार",
      "Add Product": "उत्पादन जोडा",
      "Add Expense": "खर्चाचा व्हाउचर जोडा",
      "Expense Claim Report": "खर्चाचा अहवाल",
      "User Report": "वापरकर्ता अहवाल",
      "Sent SMS Report": "एसएमएस रिपोर्ट",
      "Key Setting": "की सेटिंग",
      "Message Setting": "संदेश सेटिंग",
      "SMS Setting": "एसएमएस सेटिंग",
      Member: "सदस्य",
      Employee: "कर्मचारी",
      "Add Income": "उत्पन्न जोडा",
      "Income Report": "उत्पन्न अहवाल",
      "Total Balance Sheet": "एकूण ताळेबंद",
      "Monthly Balance Sheet": "मासिक ताळेबंद",
    },
    balanceSheet: {
      "Total Balance Sheet": "एकूण ताळेबंद",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      GroupName: "गटाचे नाव",
      Submit: "जतन करा",
      "Monthly Balance Sheet": "मासिक ताळेबंद",
    },
  },
};
